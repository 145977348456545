import { Button, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material"
import React, { useEffect, useRef, useState } from "react";
import { GAMES, ASSETS } from "../data/games";
import JackpotTicker from "./JackpotTicker"

const CARD_HEIGHT = 300;

export default function JackpotPanel(props) {
    let tick = 0;
    let source_id = props.source_id;

    const renderGameTile = (image) => {
        return (
            <Grid item xs={2}>
                <Card
                    key={image}
                    sx={{
                        borderRadius: '15px'
                    }}
                >
                    <CardMedia
                        image={image}
                        sx={{
                            height: CARD_HEIGHT,
                            // width: CARD_WIDTH,
                            backgroundSize: 'cover'
                        }}
                    />
                </Card>
            </Grid>
        )
    }

    const renderPotTickers = (source_id) => {
        let tickers = [];
        let jackpotData = props.jackpotData;
        // let data = jackpotData ? jackpotData : jpData;
        if (jackpotData && jackpotData[source_id]) {
            // render the individual tickers here
            jackpotData[source_id].pots.map((_pot, index) => {
                tickers.push(
                    <Grid key={index} item>
                        <JackpotTicker
                            authToken={props.authToken}
                            game={{ brand: "thrilltech:brand1", gamecode: source_id }}
                            jackpotData={jackpotData[source_id]}
                            tick={tick}
                            selectedPotIndex={index}
                            noAutoIncrement
                        />
                    </Grid>
                )
            })
        }
        return tickers
    }

    const renderSeedTickers = (source_id) => {
        let tickers = [];
        let jackpotData = props.jackpotData;
        // let data = jackpotData ? jackpotData : jpData;
        if (jackpotData && jackpotData[source_id]) {
            // calculate the seed pots based on the current value of each pot
            // render the individual tickers here
            let seedPot = {};
            jackpotData[source_id].pots.map((pot, index) => {
                seedPot.id = pot.id;
                switch (pot.id) {
                    case "mini":
                        seedPot.current_value = (pot.current_value - 50) * 0.33;
                        break;
                    case "minor":
                        seedPot.current_value = (pot.current_value - 500) * 0.66;
                        break;
                    case "major":
                        seedPot.current_value = (pot.current_value - 5000) * 0.66;
                        break;
                    case "mega":
                        seedPot.current_value = (pot.current_value - 50000) * 0.25;
                        break;
                    case "giga":
                        seedPot.current_value = (pot.current_value - 500000) * 0.25;
                        break;
                    case "ultra":
                        seedPot.current_value = (pot.current_value - 5000000) * 0.25;
                        break;
                }

                const renderJackpotValue = (currency, pot) => {
                    if (pot) {
                        return `€${Number((Math.round(pot.current_value * 100) / 100).toFixed(2)).toLocaleString()}`
                    }
                }

                tickers.push(
                    <Grid key={index} item>
                        <div className="JackpotTicker">
                            <b>{seedPot.id.toUpperCase()} JACKPOT: </b> <br />
                            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{renderJackpotValue(jackpotData[source_id].currency, seedPot)}</span>
                        </div>
                    </Grid>
                )
            })
        }
        return tickers
    }

    const renderContributionValue = (source_id, factor=1.0) => {
        if (props.jackpotData[source_id]) {
            return `${(props.jackpotData[source_id].contribution_type.Fixed*factor).toFixed(2)}`
        }
    }

    const renderContributionType = (source_id) => {
        if (props.jackpotData[source_id]) {
            let contribution = props.jackpotData[source_id].contribution_type.Fixed * 0.8;
            return <Typography
                variant="h6"
                align="center"
                sx={{ color: "#bffa73" }}
            >
                Current Pot - €{renderContributionValue(source_id, 1.0 - props.GGRPercent)} per bet
            </Typography>
        }
    }

    const renderGGR = (source_id) => {
        if (props.jackpotData[source_id]) {
            let value = props.jackpotData[source_id].pots[0].current_value;
            return `€${Number(((value - 50) * 1.72).toFixed(2)).toLocaleString()}`
        }
    }

    return (
        <React.Fragment>
            <Typography
                variant="h4"
                align="center"
                sx={{
                    color: "#bffa73"
                }}>
                {props.title}
            </Typography>
            <br />
            <Typography
                variant="h5"
                align="center"
                sx={{
                    color: "white"
                }}
            >
                {props.description}
            </Typography>            
            <br />
            {renderContributionType(source_id)}
            <Grid
                container
                spacing={2}
                justifyContent="center"
                textAlign="center"
                direction="row-reverse"
            >
                {renderPotTickers(source_id)}
            </Grid>
            <Grid
                container
                spacing={2}
                justifyContent='space-around'
                sx={{ marginTop: '10px' }}
                columns={10}
            >
                {ASSETS[source_id].map(image => renderGameTile(image))}
                {/* {GAMES.map(game => renderGameTile(game))}                 */}
            </Grid>
            <br />
            <Typography
                variant="h6"
                align="center"
                sx={{ color: "#bffa73" }}
            >
                Next Pot Seed: 25% of €{renderContributionValue(source_id, 1-props.GGRPercent)} bet:
            </Typography>
            <Grid
                container
                spacing={2}
                justifyContent="center"
                textAlign="center"
                direction="row-reverse"
            >
                {renderSeedTickers(source_id)}
            </Grid>

            <br />
            <Typography
                variant="h6"
                align="center"
                sx={{ color: "#bffa73" }}
            >
                Operator GGR - €0.02 cents per bet
            </Typography>
            <Grid
                container
                justifyContent="center"
                textAlign="center"
                direction="column"
            >
                <Grid container direction="row" justifyContent="center">
                    <Typography
                        variant="h4"
                        align="center"
                        sx={{
                            margin: "15px",
                            padding: "15px",
                            backgroundColor: "#3b335e",
                            color: "#bffa73",
                            border: "2px dashed #bffa73",
                            borderRadius: "10px",
                            width: "200px"
                        }}
                    >
                        {renderGGR(source_id)}
                    </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="center" spacing={2}>
                    <Button
                        variant="outlined"
                        sx={{
                            width: "175px",
                            margin: "15px",
                            backgroundColor: "#2c1d5b",
                            color: "white",
                            borderColor: "white"
                        }}
                        onClick={(e) => props.onOptinClick(e, source_id, true)}
                    >
                        Opt-in
                    </Button>

                    <Button
                        variant="contained"
                        sx={{
                            width: "175px",
                            margin: "15px",
                            backgroundColor: "#bef973",
                            color: "#2c1d5b"
                        }}
                        onClick={(e) => props.onBetClick(e, source_id)}
                    >
                        Bet €${renderContributionValue(source_id)} extra
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}