import { PLAYER_ID } from "../App";

export const PROTOCOL = "https";
export const HOST = "gateway-stg.thrillpots.io";
export const PORT = 443;
// export const PROTOCOL = "http";
// export const HOST = "localhost";
// export const PORT = 8200;

// const JACKPOT_SERVER_CONFIG = {
//     protocol: PROTOCOL,
//     host: HOST,
//     port: 8084,
// }

const GATEWAY_SERVER_CONFIG = {
    protocol: PROTOCOL,
    host: HOST,
    port: PORT,
}

function createGatewayServerURL() {
    return `${GATEWAY_SERVER_CONFIG.protocol}://${GATEWAY_SERVER_CONFIG.host}:${GATEWAY_SERVER_CONFIG.port}`;
}

// function createJackpotServerURL() {
//     return `${JACKPOT_SERVER_CONFIG.protocol}://${JACKPOT_SERVER_CONFIG.host}:${JACKPOT_SERVER_CONFIG.port}`;
// }

export class APIClient {
    constructor(protocol, host, port) {
        this.gwBaseUrl = createGatewayServerURL(protocol, host, port);
        // this.tpBaseUrl = createJackpotServerURL(protocol, host, port);
        this.token = null;
    }

    async authenticate() {
        let url = `${this.gwBaseUrl}/authenticate/service`;
    
        try {
            let resp = await fetch(url, {
                method: "POST",
                mode: "cors",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    "name": "tt_jp_client",
                    "brand": "thrilltech",
                    "secret": "1234567890"
                })
            });
        
            if (resp.status == 200) {
                let data = await resp.json();
                this.token = data.token;
                return data.token
            }
        } catch (e) {
            console.error(e);
        }
    }

    async getGWJackpotForSource(authToken, gameCode, brand) {
        if (!authToken) return;
        let url = `${this.gwBaseUrl}/jackpots/instances/source/${gameCode}/brand/${brand}?player_id=${PLAYER_ID}`;

        let resp = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authToken}`
            }
        });

        let result = null;
        if (resp.status == 200) {
            result = await resp.json();
        }

        return result;
    }

    // ? Can't call thrillpots/service directly from outside
    // async getTPJackpotForSource(authToken, gameCode, brand) {
    //     if (!authToken) return;
    //     let url = `${this.tpBaseUrl}/instances/source/${gameCode}/brand/${brand}?player_id=${PLAYER_ID}`;

    //     let resp = await fetch(url, {
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": `Bearer ${authToken}`
    //         }
    //     });

    //     let result = null;
    //     if (resp.status == 200) {
    //         result = await resp.json();
    //     }

    //     return result;
    // }

    async optIn(authToken, jackpotId, playerId, brandId, optInFlag) {
        if (authToken) {
            let url = `${this.gwBaseUrl}/jackpots/optinout`;

            let resp = await fetch(url, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    instance_id: jackpotId,
                    player_id: playerId,
                    brand_id: brandId,
                    player_country: "UK",
                    opt_in: optInFlag
                })
            });

            return (resp.status == 200)
        } else {
            return false;
        }
    }

    async contribute(authToken, contributeData) {
        if (authToken) {
            let url = `${this.gwBaseUrl}/jackpots/contribute/source`;

            let resp = await fetch(url, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(contributeData)
            });

            let data = null;
            try {
                data = await resp.json()
            } catch (e) {
                console.warn("Failed to convert contribute response to JSON");
            }

            return {
                status: resp.status,
                data
            }

        }
    }

}

