import { Button, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material"
import React, { useEffect, useRef, useState } from "react";
import { GAMES, ASSETS } from "../data/games";
import JackpotTicker from "./JackpotTicker"
import JackpotPanel from "./JackpotPanel";

const POT_DISPLAY_PERIOD = 5000;
const CARD_HEIGHT = 300;
// const CARD_WIDTH = 250;

export default function Lobby(props) {
    let [tick, setTick] = useState(0);
    // let [jackpotData, setJackpotData] = useState(props.jackpotData);

    useEffect(() => {
        let id = setInterval(() => {
            setTick((tick) => tick + 1);
        }, POT_DISPLAY_PERIOD);
        return () => {
            clearInterval(id);
        }
    }, [])

    const onGameTileClicked = (e, game) => {
        e.preventDefault();
        props.openGame(game);
    }

    const renderGameTile = (image) => {
        return (
            <Grid item xs={2}>
                <Card
                    key={image}
                    sx={{
                        borderRadius: '15px'
                    }}
                >
                    <CardMedia
                        image={image}
                        sx={{
                            height: CARD_HEIGHT,
                            // width: CARD_WIDTH,
                            backgroundSize: 'cover'
                        }}
                    />
                </Card>
            </Grid>
        )
    }

    const renderPotTickers = (source_id) => {
        let tickers = [];
        let jackpotData = props.jackpotData;
        // let data = jackpotData ? jackpotData : jpData;
        if (jackpotData && jackpotData[source_id]) {
            // render the individual tickers here
            jackpotData[source_id].pots.map((_pot, index) => {
                tickers.push(
                    <Grid key={index} item>
                        <JackpotTicker
                            authToken={props.authToken}
                            game={{ brand: "thrilltech:brand1", gamecode: source_id }}
                            jackpotData={jackpotData[source_id]}
                            tick={tick}
                            selectedPotIndex={index}
                            noAutoIncrement
                        />
                    </Grid>
                )
            })
        }
        return tickers
    }

    const renderSeedTickers = (source_id) => {
        let tickers = [];
        let jackpotData = props.jackpotData;
        // let data = jackpotData ? jackpotData : jpData;
        if (jackpotData && jackpotData[source_id]) {
            // calculate the seed pots based on the current value of each pot
            // render the individual tickers here
            let seedPot = {};
            jackpotData[source_id].pots.map((pot, index) => {
                seedPot.id = pot.id;
                switch (pot.id) {
                    case "mini":
                        seedPot.current_value = (pot.current_value - 50) * 0.33;
                        break;
                    case "minor":
                        seedPot.current_value = (pot.current_value - 500) * 0.66;
                        break;
                    case "major":
                        seedPot.current_value = (pot.current_value - 5000) * 0.66;
                        break;
                    case "mega":
                        seedPot.current_value = (pot.current_value - 50000) * 0.25;
                        break;
                    case "giga":
                        seedPot.current_value = (pot.current_value - 500000) * 0.25;
                        break;
                    case "ultra":
                        seedPot.current_value = (pot.current_value - 5000000) * 0.25;
                        break;
                }

                const renderJackpotValue = (currency, pot) => {
                    if (pot) {
                        return `€${Number((Math.round(pot.current_value * 100) / 100).toFixed(2)).toLocaleString()}`
                    }
                }

                tickers.push(
                    <Grid key={index} item>
                        <div className="JackpotTicker">
                            <b>{seedPot.id.toUpperCase()} JACKPOT: </b> <br />
                            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{renderJackpotValue(jackpotData[source_id].currency, seedPot)}</span>
                        </div>
                    </Grid>
                )
            })
        }
        return tickers
    }

    const renderHeading = (source_id) => {
        if (props.jackpotData[source_id]) {
            let label = source_id == "demo-example1" ?
                `Daily Must-Drop ${props.jackpotData[source_id].pots.length}-Tier Jackpot (10c bet) on a game selection (Hybrid Tipping Point) - €0.10` :
                `Site-Wide ${props.jackpotData[source_id].pots.length}-Tier Jackpot Across all Games (Hit-rate-based tipping point) - €0.20`
            if (props.jackpotData[source_id]) {
                return <Typography
                    variant="h5"
                    align="center"
                    sx={{
                        color: "white"
                    }}
                >
                    {label}
                </Typography>
            }
        }
    }

    const renderContributionType = (source_id) => {
        if (props.jackpotData[source_id]) {
            let contribution = props.jackpotData[source_id].contribution_type.Fixed * 0.8;
            return <Typography
                variant="h6"
                align="center"
                sx={{ color: "#bffa73" }}
            >
                Current Pot - €{contribution.toFixed(2)} per bet
            </Typography>
        }
    }

    const renderGGR = (source_id) => {
        if (props.jackpotData[source_id]) {
            let value = props.jackpotData[source_id].pots[0].current_value;
            return `€${Number(((value - 50) * 1.72).toFixed(2)).toLocaleString()}`
        }
    }

    const getTierCount = (source_id) => {
        if (props.jackpotData[source_id]) {
            return props.jackpotData[source_id].pots.length
        }
        return 0
    }

    return (
        <React.Fragment>
            <JackpotPanel
                jackpotData={props.jackpotData}
                source_id="demo-example1"
                title="Example 1"
                description={`Daily Must-Drop ${getTierCount("demo-example1")}-Tier Jackpot (10c bet) on a game selection (Hybrid Tipping Point) - €0.10`}
                onBetClick={props.onBetClick}
                onOptinClick={props.onOptinClick}
                GGRPercent={0.2}
            />
            <hr />
            <JackpotPanel
                jackpotData={props.jackpotData}
                source_id="demo-example2"
                title="Example 2"
                description={`Site-Wide ${getTierCount("demo-example2")}-Tier Jackpot Across all Games (Hit-rate-based tipping point) - €0.20`}
                onBetClick={props.onBetClick}
                onOptinClick={props.onOptinClick}
                GGRPercent={0.1}
            />
        </React.Fragment>
    )
}
