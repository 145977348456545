import './App.css';
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Container } from '@mui/system';
import Lobby from './components/Lobby';
import { APIClient } from './data/APIClient';

export const PROTOCOL = "https";
export const HOST = "gateway-stg.thrillpots.io";
export const PORT = 443;
const WS_PROTOCOL = "wss"
const EVENT_STREAM_URL = `${WS_PROTOCOL}://${HOST}:${PORT}/events`;

export const PLAYER_ID = "player_00001";
export const BRAND_ID = "thrilltech:brand1";

let gameroundId = 20000;


let socket = null;

function App(props) {
  let [viewType, setViewType] = useState(0);
  let [authToken, setAuthToken] = useState(null);
  let [jackpotData, setJackpotData] = useState({});

  let api = new APIClient("http", HOST, PORT);

  useEffect(() => {
    auth();
  }, []);

  const auth = async () => {
    let result = await api.authenticate();
    if (result) {
      setAuthToken(result);
      setViewType(1)
    } else {
      console.error("Authenticationg failed");
      setTimeout(() => {
        auth();
      }, 2000);
    }
  }

  useEffect(() => {
    if (authToken) {
      if (!socket) {
        startAutoContribution();
        connectToEventStream()
      }
      api.getGWJackpotForSource(authToken, "demo-example1", "thrilltech:brand1")
        .then(jp => {
          setJackpotData((prev) => {
            let newState = {...prev};
            newState["demo-example1"] = jp
            return newState
          });  
        })
      api.getGWJackpotForSource(authToken, "demo-example2", "thrilltech:brand1")
      .then(jp => {
        setJackpotData((prev) => {
          let newState = {...prev};
          newState["demo-example2"] = jp
          return newState
        });
      })
  }
  }, [authToken])

  const startAutoContribution = () => {
    setInterval(() => {
      ["demo-example1", "demo-example2"].map(code => onBetClick(null, code))
    }, 1000)
  }

  const connectToEventStream = () => {
    console.debug(`Connecting to ${EVENT_STREAM_URL}`);
    socket = new WebSocket(EVENT_STREAM_URL);

    socket.addEventListener('open', (e) => {
      console.debug("Connected to server")
      // send the auth message
      let msg = { 
        "action": { 
          "Authenticate": { 
            "auth_token": authToken 
          } 
        } 
      };
      socket.send(JSON.stringify(msg))
    })
    socket.addEventListener('message', (e) => {
      try {
        processMessage(JSON.parse(e.data));
      } catch (e) {
        console.error(e)
      }
    })
    socket.addEventListener('close', (e) => {
      console.debug("Lost connection to server");
      socket = null;
      setAuthToken(null);
      auth();
    })
  }

  const processMessage = async (msg) => {
    switch (msg.event_type) {
      case "JackpotUpdateEvent":
        let jpData = { ...jackpotData };

        msg.data.allowed_sources.map(g => {
          if (g === "demo-example1" || g === "demo-example2") {
            jpData[g] = msg.data;
          }
        })

        setJackpotData((prev) => {
          let newState = {...prev};
          for (let key in jpData) {
            for (let prop in jpData[key]) {
              newState[key][prop] = jpData[key][prop];
            }
          }
          return newState
        });
        break;

      case "WinEvent":
        break;

      default:
        console.warn(`Unhandled message: ${msg.event_type}`);
    }
  }

  const openGame = (game) => {
    setViewType(2);
  }

  const backToLobby = () => {
    setViewType(1);
  }

  const onOptinClick = (e, gamecode, flag) => {
    e.preventDefault();
    
    const optin = async() => {
      await api.optIn(authToken, jackpotData[gamecode].id, PLAYER_ID, BRAND_ID, flag);
    }

    optin()
  }

  const onBetClick = (e, gamecode) => {
    if (e) { e.preventDefault() }
    const contribute = async() => {
      let result = await api.contribute(
        authToken, 
        {
            token: "token_00001",
            player_id: PLAYER_ID, 
            brand_id: BRAND_ID,
            source_id: gamecode,
            player_country: "UK",
            gameround_id: `${gameroundId++}`,
            currency: "EUR",
            base_wager: 5,
        }  
      )

      let updatedJackpot = await api.getGWJackpotForSource(authToken, gamecode, BRAND_ID);

      setJackpotData((prev) => {
        let newState = {...prev};
        newState[gamecode] = updatedJackpot;
        return newState
      });  

    }

    contribute()
  }

  return (
    <Container style={{ backgroundColor: "#2c1d5b" }} maxWidth="l">
      <div style={{backgroundColor: "#2c1d5b"}}>
        <center>
          <img src="logo.png" height={40} style={{ marginRight: 10 }} />
        </center>
        <hr/>
      </div>
      <main style={{ margin: "20px", height: "100vh"}}>
        <Lobby 
          authToken={authToken} 
          jackpotData={jackpotData} 
          openGame={openGame}
          onBetClick={onBetClick}
          onOptinClick={onOptinClick}
        />
      </main>
    </Container>
  );
}

export default App;
