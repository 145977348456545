import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material"
import { APIClient } from "../data/APIClient";

const PERIOD_PER_INCREASE = 250;

export default function JackpotTicker(props) {
    let [jackpotData, setJackpotData] = useState(props.jackpotData);
    let selectedPotIndex = props.selectedPotIndex;
    let tick = props.tick | 0;
    let api = new APIClient();

    useEffect(() => {
        const getJackpot = async () => {
            let game = props.game;
            let jackpot = await api.getGWJackpotForSource(props.authToken, game.gamecode, game.brand);
            setJackpotData(jackpot);
        }

        getJackpot();
    }, [props.authToken])

    useEffect(() => {
        if (props.noAutoIncrement != undefined) return;

        let interval = setInterval(() => {
            let oldData = props.jackpotData ? props.jackpotData : jackpotData;
            if (oldData) {
                let jpData = { ...oldData };
                jpData.pots.map((pot, index) => {
                    let increasePerSecond = props.game.increasePerSecond ? props.game.increasePerSecond : 0.01;
                    console.log("[BEFORE] pot.current_value = ", pot.current_value)
                    pot.current_value += increasePerSecond/(1000/PERIOD_PER_INCREASE) * (index+1);
                    console.log("pot.current_value = ", pot.current_value)
                });
                setJackpotData(jpData);
            }
        }, PERIOD_PER_INCREASE);

        return (() => clearInterval(interval))
    });

    const renderJackpot = () => {
        let data = props.jackpotData ? props.jackpotData : jackpotData;
        if (data) {
            let potIndex = selectedPotIndex != undefined ? selectedPotIndex : tick % data.pots.length;
            let pot = data.pots[potIndex];
            return (
                <div className="JackpotTicker">
                    <b>{pot.id.toUpperCase()} JACKPOT: </b> <br/>
                    <span style={{fontSize: '20px', fontWeight: 'bold'}}>{renderJackpotValue(data.currency, pot)}</span>
                </div>
            )
        }
    }

    const renderJackpotValue = (currency, pot) => {
        if (pot) {
            // return `${((Math.
            //     round(pot.current_value*100)/100)
            //     .toFixed(2)+0)
            //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            // } ${currency}`
            return renderMoneyValue(pot.current_value)
        }
    }

    const renderMoneyValue = (v) => {
        return `€${Number((Math.round(v*100)/100).toFixed(2)).toLocaleString()}`
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            {renderJackpot()}
        </Box>
    )
}