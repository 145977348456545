export const ASSETS = {
    "demo-example1": [
        "/example1/tile1.png",
        "/example1/tile2.png",
        "/example1/tile3.png",
        "/example1/tile4.png",
        "/example1/tile5.png"
    ],
    "demo-example2": [
        "/example2/tile1.png",
        "/example2/tile2.png",
        "/example2/tile3.png",
        "/example2/tile4.png",
        "/example2/tile5.png",
        "/example2/tile6.png",
        "/example2/tile7.png",
        "/example2/tile8.png",
        "/example2/tile9.png",
        "/example2/tile10.png"
    ]
}

export const GAMES = [
    {
        name: "Book of Ra",
        gamecode: "game1_slot",
        brand: "thrilltech:brand1",
        image: "https://www.novomatic.com/sites/default/files/styles/produkte_gallery/public/2021-05/wLwdMPM5sblDa0V.jpg?itok=lZUqlDUu",
        gameImage: "https://casinohex.co.za/wp-content/uploads/book-of-ra-novomatic-slot.png",
        increasePerSecond: 0.01,
    },
    {
        name: "9K Kong in Vegas",
        gamecode: "game3_slot",
        brand: "thrilltech:brand1",
        image: "https://i8.amplience.net/i/veikkaus/9k-kong-in-vegas_tile-image-with-logo?w=480&qlt=80&fmt=auto",
        gameImage: "https://www.bigwinboard.com/wp-content/uploads/2022/10/9K_Kong_Main_Game_Screen.jpg",
        increasePerSecond: 0.02,
    },
    {
        name: "Starburst",
        gamecode: "game2_slot",
        brand: "thrilltech:brand1",
        image: "https://www.casinowow.com/media/uploads/Starburst-464x302-5d91d42e7e0f1.jpg",
        gameImage: "https://services.onlineslots.com/cms-assets/game-demos/en-starburst/_1250x703_crop_center-center_82_line/28360/starburst-demo-1.jpg",
        increasePerSecond: 0.01,
    },
    {
        name: "Money Train 2",
        gamecode: "game4_slot",
        brand: "thrilltech:brand1",
        image: "https://www.unibet.com/polopoly_fs/1.1531793.1636545377!/image.png?derivative=800x600&width=1050",
        gameImage: "/moneytrain2.png",
        increasePerSecond: 0.01,
    },
]
